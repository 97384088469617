<template>
  <div
    class="min-h-screen flex flex-col w-full"
  >
    <NavBar />
    <main class="flex flex-col flex-1">
      <slot />
    </main>
  </div>
</template>
